<template>
  <b-overlay
    variant="white"
    :show="showOverlay"
    spinner-variant="primary"
    blur="0"
    opacity=".75"
    rounded="sm"
  >
    <form-wizard
      color="#7367F0"
      :title="null"
      :subtitle="null"
      shape="circle"
      finish-button-text="Submit"
      back-button-text="Previous"
      class="mb-3"
      @on-complete="formSubmitted"
    >
      <tab-content
        title="Campaign"
        :before-change="validationFormCampaign"
      >
        <b-card>
          <validation-observer ref="campaignOverviewRules" tag="form">
            <CampaignOverview
              :is-edit-mode="false"
              :campaign="campaign_overview"
              :business-objective-option="objectiveOptions"
              :campaign-status-options="campaignStatusOptions"
            />
          </validation-observer>
        </b-card>
      </tab-content>
      <tab-content
        title="Briefing"
        :before-change="validationFormBriefing"
      >
        <b-card>
          <validation-observer ref="briefingRules" tag="form">
            <Briefing
              :landing-page-type-options="landingPageTypeOptions"
              :report-options="reportSystemOptions"
              :creative-briefing-info="creative_briefing_info"
              :web-sol-briefing="web_sol_briefing"
              :media-briefing="media_briefing"
              :chatbot-section-info="chatbot_section_info"
              :document-section="document_section"
              :seo-requirements="seoRequirementsSection"
              :language-options="languageOptions"
              @get-creative-briefing-data="creative_briefing_info.campaign_creative_brief = {
                ...creative_briefing_info.campaign_creative_brief,
                ...$event
              }"
            />
          </validation-observer>
        </b-card>
      </tab-content>
      <tab-content
        title="Staff"
        :before-change="validationFormStaff"
      >
        <b-card>
          <validation-observer ref="staffRules" tag="form">
            <StaffAssignment
              :staff-assignment="staff_assignment"
              :am-options="amOptions"
              :optimizer-options="optimizerOptions"
              :copy-writer-options="copyWriterOptions"
              :designer-options="designerOptions"
              :system-support-options="systemSupportOptions"
              :data-analyst-options="dataAnalystOptions"
              :influencer-options="influencerOptions"
              :digital-associate-options="digitalAssociateOptions"
              :sale-options="saleOptions"
              :viewers-options="viewerOptions"
              :point-options="userLevelPointOptions"
              :ad-platform-options="adPlatformOptions"
              :zero-point-id="zeroPointId"
            />
          </validation-observer>
        </b-card>
      </tab-content>
      <tab-content
        title="Campaign Products"
        :before-change="validationFormCampaignProducts"
      >
        <b-card v-if="dataBind">
          <validation-observer ref="campaignProductRules" tag="form">
            <CampaignProducts
              :campaign-products="campaign_products"
              @calculateTotalPrice="onCalculateTotalPrice"
            />
          </validation-observer>
        </b-card>
      </tab-content>
      <tab-content
        title="Fill Media Plan"
        :before-change="validationFormFillMedia"
      >
        <b-card>
          <validation-observer ref="fillMediaRules" tag="form">
            <FillMediaPlan
              :fill-media="fillMedia"
              :has-change="hasChange"
              :campaign_products="campaign_products"
              :kpi-unit-options="kpiUnitOptions"
            />
          </validation-observer>
        </b-card>
      </tab-content>
    </form-wizard>
  </b-overlay>
</template>

<script>
import store from '@/store'
import { FormWizard, TabContent } from 'vue-form-wizard'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import FillMediaPlan from '@/views/heroai/campaigns/components/create/FillMediaPlan.vue'
import CampaignProductsModel from '@/models/Campaigns/CampaignProducts'
import CampaignOverviewModel from '@/models/Campaigns/CampaignOverview'
import CreativeBriefingInfoModel from '@/models/Campaigns/CreativeBriefing'
import WebSolutionBriefingModel from '@/models/Campaigns/WebSolutionBriefing'
import MediaBrief from '@/models/Campaigns/MediaBriefing'
import ChatbotSectionInfo from '@/models/Campaigns/ChatbotSection'
import DocSection from '@/models/Campaigns/DocumentSection'
import StaffAssign from '@/models/Campaigns/StaffAssignment'
import FillMediaInfo from '@/models/Campaigns/FillMediaPlan'
import ProductModal from '@/models/Campaigns/ProductTable'
import CampaignProducts from '@/views/heroai/campaigns/components/create/CampaignProducts.vue'
import CampaignOverview from '@/views/heroai/campaigns/components/create/CampaignOverview.vue'
import StaffAssignment from '@/views/heroai/campaigns/components/create/StaffAssignment.vue'
import Briefing from '@/views/heroai/campaigns/components/create/BriefingInformation.vue'
import ProductModalFactory from '@/factories/Campaign/ProductTable'
import Constant from '@/utils/Constant'
import SweetAlert from '@/services/SweetAlert'
import AxiosService from '@/services/AxiosService'
import ErrorService from '@/services/ErrorService'
import { ValidationObserver } from 'vee-validate'
import { DATE_PICKER_FORMAT } from '@/utils/Format'
import moment from 'moment'
import Toastification from '@/services/Toastification'
import SEORequirementsSection from '@/models/Campaigns/SEORequirementsSection'
import axiosInstance from '@/libs/axiosInstance'

export default {
  components: {
    FormWizard,
    TabContent,
    CampaignOverview,
    Briefing,
    FillMediaPlan,
    StaffAssignment,
    CampaignProducts,
    ValidationObserver,
  },
  data() {
    return {
      showOverlay: true,
      collapseType: 'border',
      campaign_products: new CampaignProductsModel({}),
      campaign_overview: new CampaignOverviewModel({}),
      creative_briefing_info: new CreativeBriefingInfoModel({}),
      web_sol_briefing: new WebSolutionBriefingModel({}),
      media_briefing: new MediaBrief({}),
      chatbot_section_info: new ChatbotSectionInfo({}),
      document_section: new DocSection({}),
      seoRequirementsSection: new SEORequirementsSection({}),
      staff_assignment: new StaffAssign({}),
      fillMedia: new FillMediaInfo({}),
      product_table: new ProductModal({}),
      opportunity_id: '',
      retain_budget: 0,
      hasChange: true,
      dataBind: false,
      submitted: false,
      management_fee: '',
      walletBudgetValidation: [],
      passedWalletValidation: true,
      kpiUnitOptions: [],
      campaignStatusOptions: [],
      objectiveOptions: [],
      landingPageTypeOptions: [],
      languageOptions: [],
      reportSystemOptions: [],
      userLevelPointOptions: [],
      adPlatformOptions: [],
      amOptions: [],
      optimizerOptions: [],
      copyWriterOptions: [],
      designerOptions: [],
      systemSupportOptions: [],
      dataAnalystOptions: [],
      influencerOptions: [],
      digitalAssociateOptions: [],
      saleOptions: [],
      viewerOptions: [],
      passedDuplicateProductValidation: true,
    }
  },
  computed: {
    zeroPointId() {
      return this.userLevelPointOptions.find(userLevelPointOption => userLevelPointOption.text === '0')?.value || ''
    },
  },
  created() {
    this.getCampaignDropdown()
  },
  mounted() {
    this.resetStore()
    this.getCampaignData()
    store.commit('heroAiCampaign/setStaffAssignment', this.staff_assignment)
  },
  methods: {
    async getCampaignDropdown() {
      try {
        const slugs = 'landing_page_types,campaign_status,objectives,report_systems,languages,users,user_level_points,ad_platform,kpi_units'
        const { data: { data } } = await axiosInstance.get(`dropdown-options/campaign-dropdown-options?slugs=${slugs}`)
        this.kpiUnitOptions = data.kpi_units
        this.campaignStatusOptions = data.campaign_status
        this.objectiveOptions = data.objectives
        this.landingPageTypeOptions = data.landing_page_types
        this.languageOptions = data.languages
        this.reportSystemOptions = data.report_systems
        this.userLevelPointOptions = data.user_level_points.map(userLevelPoint => ({ ...userLevelPoint, text: userLevelPoint.text.toString() }))
        this.adPlatformOptions = data.ad_platform

        /* user options */
        this.amOptions = data.am
        this.optimizerOptions = data.optimizer
        this.copyWriterOptions = data.copy_writer
        this.designerOptions = data.designer
        this.systemSupportOptions = data.system_support
        this.dataAnalystOptions = data.data_analyst
        this.influencerOptions = data.influencer
        this.digitalAssociateOptions = data.digital_associate
        this.saleOptions = data.sale
        this.viewerOptions = data.viewers
      } catch (error) {
        this.$swal({ ...SweetAlert.error, html: ErrorService.extractError(error) })
      }
    },

    async handleAssignCreativeBriefing() {
      try {
        if (!Object.keys(this.creative_briefing_info).length) return

        const { unique_id: creativeBriefingId } = this.creative_briefing_info.campaign_creative_brief

        if (!creativeBriefingId) return

        const campaignId = this.$route.params.id
        const fullName = this.$store.getters['heroAiAuthentications/fullName'] || ''

        await axiosInstance.patch(Constant.apiURL.campaignCreativeBrief.assign(creativeBriefingId), {
          campaign_id: campaignId,
          assigned_by: fullName,
        })
      } catch (error) {
        this.$swal({ ...SweetAlert.error, html: ErrorService.extractError(error) })
      }
    },

    async formSubmitted() {
      if (!this.submitted) {
        this.$swal({ ...SweetAlert.loading, text: 'Retaining campaigns.' })

        this.submitted = true
        this.campaign_overview = store.getters['heroAiCampaign/getCampaign']
        this.creative_briefing_info = store.getters['heroAiCampaign/getCreativeBriefingInfo']
        this.web_sol_briefing = store.getters['heroAiCampaign/getWebSolutionBriefing']
        this.media_briefing = store.getters['heroAiCampaign/getMediaBriefing']
        this.chatbot_section_info = store.getters['heroAiCampaign/getChatbotSectionInfo']
        this.document_section = store.getters['heroAiCampaign/getDocumentSection']
        this.seoRequirementsSection = store.getters['heroAiCampaign/getSEORequirementsSection']
        this.staff_assignment = store.getters['heroAiCampaign/getStaffAssignment']
        this.campaign_products = store.getters['heroAiCampaign/getCampaignProducts']
        this.fillMedia = store.getters['heroAiCampaign/getFillMedia']
        this.opportunity_id = store.getters['heroAiCampaign/getOpportunityId']

        /* eslint no-underscore-dangle: ["error", { "allow": ["_id"] }] */
        const NewStaffAssignment = {
          account_manager: this.staff_assignment.account_manager.id || '',
          account_manager_point: this.staff_assignment.account_manager.user_level_points || '',

          optimizer_1_id: this.staff_assignment.optimizer_1.id || '',
          optimizer_1_point: this.staff_assignment.optimizer_1.user_level_points || '',
          optimizer_1_ad_platform: this.staff_assignment.optimizer_1.ad_platform || '',

          optimizer_2_id: this.staff_assignment.optimizer_2.id || '',
          optimizer_2_point: this.staff_assignment.optimizer_2.user_level_points || '',
          optimizer_2_ad_platform: this.staff_assignment.optimizer_2.ad_platform || '',

          optimizer_3_id: this.staff_assignment.optimizer_3.id || '',
          optimizer_3_point: this.staff_assignment.optimizer_3.user_level_points || '',
          optimizer_3_ad_platform: this.staff_assignment.optimizer_3.ad_platform || '',

          optimizer_4_id: this.staff_assignment.optimizer_4.id || '',
          optimizer_4_point: this.staff_assignment.optimizer_4.user_level_points || '',
          optimizer_4_ad_platform: this.staff_assignment.optimizer_4.ad_platform || '',

          optimizer_5_id: this.staff_assignment.optimizer_5.id || '',
          optimizer_5_point: this.staff_assignment.optimizer_5.user_level_points || '',
          optimizer_5_ad_platform: this.staff_assignment.optimizer_5.ad_platform || '',

          copy_writer_1_id: this.staff_assignment.copy_writer_1.id || '',
          copy_writer_1_point: this.staff_assignment.copy_writer_1.user_level_points || '',
          copy_writer_2_id: this.staff_assignment.copy_writer_2.id || '',
          copy_writer_2_point: this.staff_assignment.copy_writer_2.user_level_points || '',
          copy_writer_3_id: this.staff_assignment.copy_writer_3.id || '',
          copy_writer_3_point: this.staff_assignment.copy_writer_3.user_level_points || '',
          graphic_designer_1_id: this.staff_assignment.graphic_designer_1.id || '',
          graphic_designer_1_point: this.staff_assignment.graphic_designer_1.user_level_points || '',
          graphic_designer_2_id: this.staff_assignment.graphic_designer_2.id || '',
          graphic_designer_2_point: this.staff_assignment.graphic_designer_2.user_level_points || '',
          graphic_designer_3_id: this.staff_assignment.graphic_designer_3.id || '',
          graphic_designer_3_point: this.staff_assignment.graphic_designer_3.user_level_points || '',
          system_support: this.staff_assignment.system_support.id || '',
          system_support_point: this.staff_assignment.system_support.user_level_points || '',
          data_analyst: this.staff_assignment.data_analyst.id || '',
          data_analyst_point: this.staff_assignment.data_analyst.user_level_points || '',
          influencer_planner: this.staff_assignment.influencer_planner.id || '',
          influencer_planner_point: this.staff_assignment.influencer_planner.user_level_points || '',
          digital_associate: this.staff_assignment.digital_associate.id || '',
          digital_associate_point: this.staff_assignment.digital_associate.user_level_points || '',
          sales: this.staff_assignment.sales || '',
          viewers: this.staff_assignment.viewers || [],
          seo_members: this.staff_assignment.seo_members || [],
        }
        this.campaign_overview.bonus = this.campaign_products.bonus

        const payload = {
          campaign_overview: this.campaign_overview,
          creative_briefing_info: this.creative_briefing_info,
          web_sol_briefing: this.web_sol_briefing,
          media_briefing: this.media_briefing,
          chatbot_section_info: this.chatbot_section_info,
          document_section: this.document_section,
          seo_requirements_section: this.seoRequirementsSection,
          staff_assignment: NewStaffAssignment,
          campaign_products: this.campaign_products,
          fill_media: this.fillMedia,
          opportunity_id: this.opportunity_id,
          retain_budget: this.retain_budget,
        }
        try {
          const response = await AxiosService.patch(`${Constant.apiURL.updateRetainCampaign}/${this.$route.params.id}`, null, payload)

          if (response.status) {
            await this.handleAssignCreativeBriefing()

            this.resetStore()
            await this.$swal({ ...SweetAlert.success, text: 'Campaign retained successfully!' })
            this.$router.push({ name: 'campaign-detail', params: { id: this.$route.params.id } })
          }
        } catch (error) {
          this.$swal({ ...SweetAlert.error, html: ErrorService.extractError(error) })
          if (error.response.status === 401) {
            this.$router.push({ name: 'heroai-logout' })
          }
          if (error.response.status === 404) {
            await this.$router.push({ name: 'page-not-found' })
          }
        } finally {
          this.submitted = false
        }
      }
    },

    // ปุ่ม Next หน้าที่สี่
    validationFormCampaignProducts() {
      let toastObject

      // ตรวจสอบเงื่อนไขสำหรับ Wallet
      if (this.campaign_products.products && this.campaign_products.products.length > 0) {
        const validationData = []

        // หา Wallet Budget Id ที่ถูกเลือกเอาไว้ทั้งหมดออกมา
        const walletBudgetIds = this.campaign_products.products.filter(product => product.wallet_budget_id && product.wallet_budget_id !== '').map(product => product.wallet_budget_id)

        // ตัดให้เลือกเฉพาะรายการที่ไม่ซ้ำกัน
        const uniqueWalletBudgetIds = [...new Set(walletBudgetIds)]

        // ประมวลผลรวมของ Net Budget สำหรับแต่ล่ะ Wallet ว่ามีค่ามากกว่า Remain Amount หรือไม่
        uniqueWalletBudgetIds.forEach(walletBudgetId => {
          let walletName
          let remainAmount
          let netBudgets
          let totalNetBudget

          if (walletBudgetId !== 'retain_budget') {
            walletName = this.$store.getters['SourceWalletBudgetOptions/getWalletName'](walletBudgetId)
            remainAmount = parseFloat(this.$store.getters['SourceWalletBudgetOptions/getRemainAmount'](walletBudgetId))

            if (this.campaign_products.client_pay_for_media) {
              netBudgets = this.campaign_products.products.filter(product => product.wallet_budget_id === walletBudgetId && !product.is_media).map(product => parseFloat(product.net_budget))
            } else {
              netBudgets = this.campaign_products.products.filter(product => product.wallet_budget_id === walletBudgetId).map(product => parseFloat(product.net_budget))
            }

            totalNetBudget = netBudgets.reduce((sum, item) => sum + item, 0)
          } else {
            walletName = 'Retain Budget'
            remainAmount = parseFloat(this.retain_budget)

            if (this.campaign_products.client_pay_for_media) {
              netBudgets = this.campaign_products.products.filter(product => product.wallet_budget_id === walletBudgetId && !product.is_media).map(product => parseFloat(product.net_budget))
            } else {
              netBudgets = this.campaign_products.products.filter(product => product.wallet_budget_id === walletBudgetId).map(product => parseFloat(product.net_budget))
            }

            totalNetBudget = netBudgets.reduce((sum, item) => sum + item, 0)
          }

          validationData.push({
            wallet_budget_id: walletBudgetId,
            remain_amount: remainAmount,
            total_net_budget: totalNetBudget,
            validate_pass: totalNetBudget <= remainAmount,
            validate_message: `Total net budget amount for '${walletName}' is higher than remain amount ${totalNetBudget - remainAmount}.`,
          })
        })

        this.walletBudgetValidation = validationData
      }

      // eslint-disable-next-line consistent-return
      this.walletBudgetValidation.forEach(wallet => {
        if (wallet.validate_pass === false) {
          this.passedWalletValidation = false
          toastObject = Toastification.getContentError(wallet.validate_message)
          this.$toast(toastObject)
          return false
        }
      })

      if (this.walletBudgetValidation.length === 0) {
        toastObject = Toastification.getContentError('Please select budget for each products.')
        this.$toast(toastObject)
        this.passedWalletValidation = false
        return false
      }

      if (this.passedWalletValidation === false) {
        this.passedWalletValidation = !this.passedWalletValidation
        return false
      }

      return new Promise((resolve, reject) => {
        this.$refs.campaignProductRules.validate().then(success => {
          if (success) {
            this.hasChange = !this.hasChange
            this.campaign_products.products.forEach((x, index) => {
              this.campaign_products.products[index].ads_budget = parseFloat(x.net_budget) - ((parseFloat(x.net_budget) * parseFloat(x.management_fee)) / 100)
            })
            if (this.campaign_products.remaining < 0) {
              this.$swal('You have exceed remaining budget')
              reject()
            } else if (this.campaign_products.products && this.campaign_products.products.length > 0) {
              const totalNetBudget = this.campaign_products.products.map(x => parseFloat(x.net_budget))
              this.campaign_products.total.net_budget = totalNetBudget.reduce((partialSum, a) => partialSum + a, 0)
              const totalAdsBudget = this.campaign_products.products.map(x => parseFloat(x.ads_budget))
              this.campaign_products.total.ads_budget = totalAdsBudget.reduce((partialSum, a) => partialSum + a, 0)
              resolve(true)
            } else {
              this.$swal('Please add at least one product')
              reject()
            }
          } if (!success) {
            this.$swal('Please solve the errors in the page.')
            reject()
          } else {
            reject()
          }
        })
      })
    },
    async getCampaignData() {
      this.showOverlay = true
      try {
        this.opportunity_id = store.getters['heroAiCampaign/getOpportunityId']
        this.retain_budget = store.getters['heroAiCampaign/getRetainBudget']
        if (this.opportunity_id && this.retain_budget) {
          const response = await AxiosService.get(`${Constant.apiURL.salesforceRetainCampaignDetail}/${this.opportunity_id}/${this.$route.params.id}/${this.retain_budget}`)
          if (response.status) {
            this.campaign_overview = new CampaignOverviewModel(response.data.campaign_overview)
            if (this.campaign_overview) {
              this.campaign_overview.business_objectives = this.campaign_overview.business_objectives.toString()
              this.campaign_overview.campaign_status_id = this.campaign_overview.campaign_status_id.toString()
              this.$route.meta.breadcrumb[1].text = this.campaign_overview.name
              this.campaign_overview.brief_date = response.data.campaign_overview.brief_date || ''
              this.campaign_overview.actual_campaign_start_date = response.data.campaign_overview.campaign_start_date || ''
              this.campaign_overview.actual_campaign_end_date = response.data.campaign_overview.campaign_end_date || ''
              this.campaign_overview.paused_date = response.data.campaign_overview.paused_date || ''
              this.campaign_overview.unpaused_date = response.data.campaign_overview.unpaused_date || ''
            }

            this.creative_briefing_info = new CreativeBriefingInfoModel(response.data.creative_briefing)
            this.web_sol_briefing = new WebSolutionBriefingModel(response.data.web_solution_briefing)
            this.media_briefing = new MediaBrief(response.data.media_briefing)
            this.chatbot_section_info = new ChatbotSectionInfo(response.data.chatbot_section)
            this.document_section = new DocSection(response.data.document_section)
            this.seoRequirementsSection = new SEORequirementsSection(response.data.seo_requirements_section)

            this.staff_assignment = new StaffAssign(response.data.staff)

            this.campaign_products.management_fee = response.data.opportunity_overview.management_fee ? response.data.opportunity_overview.management_fee : '0'
            store.commit('heroAiCampaign/setManagementFee', this.campaign_products.management_fee)

            this.campaign_products.pricing_model        = response.data.opportunity_overview.pricing_model || ''
            this.campaign_products.remaining            = response.data.campaign_overview.remaining_budget
            this.campaign_products.total_budget         = response.data.campaign_overview.campaign_budget.toString()
            this.campaign_products.client_pay_for_media = response.data.opportunity_overview.client_pay_for_media
            console.log('Client Pay For Media', this.campaign_products.client_pay_for_media)

            this.fillMedia.pricing_model    = response.data.opportunity_overview.pricing_model || ''
            this.fillMedia.management_fee   = this.campaign_products.management_fee
            this.fillMedia.amount_of_cycles = response.data.opportunity_overview.duration.toString()

            const mediaPlans = ProductModalFactory.createFromJsonArray(response.data.media_plans)
            mediaPlans.forEach(data => {
              const mediaPlan = data
              mediaPlan.selected = true
              this.campaign_products.products.push(mediaPlan)
            })

            store.commit('heroAiCampaign/setRemainingAmount', this.campaign_products.remaining)
            store.commit('heroAiCampaign/setCampaignProducts', mediaPlans)
            store.commit('heroAiCampaign/setCycleAmount', this.fillMedia.amount_of_cycles)
            if (mediaPlans) {
              const TotalPrices = mediaPlans.map(x => parseFloat(x.total_price))
              const TotalPrice = TotalPrices.reduce((partialSum, a) => partialSum + a, 0)
              this.campaign_products.total_price = TotalPrice
            }

            this.campaign_products.from_cycle = response.data.from_cycle
            this.campaign_products.to_cycle   = response.data.to_cycle
          } else {
            this.$router.push({ name: 'campaigns-list' })
          }
        } else {
          this.$router.push({ name: 'campaigns-list' })
        }
      } catch (error) {
        await this.$swal({ ...SweetAlert.error, html: ErrorService.extractError(error) })

        if (error.response.status === 401) {
          await this.$router.push({ name: 'heroai-logout' })
        }
        if (error.response.status === 404) {
          await this.$router.push({ name: 'page-not-found' })
        }
      } finally {
        this.dataBind = true
        this.showOverlay = false
      }
    },
    validationFormCampaign() {
      return new Promise((resolve, reject) => {
        this.$refs.campaignOverviewRules.validate().then(success => {
          if (success) {
            resolve(true)
          } if (!success) {
            this.$swal('Please solve the errors in the page.')
            reject()
          } else {
            reject()
          }
        })
      })
    },
    validationFormBriefing() {
      return new Promise((resolve, reject) => {
        this.$refs.briefingRules.validate().then(success => {
          if (success) {
            resolve(true)
          } if (!success) {
            this.$swal('Please solve the errors in the page.')
            reject()
          } else {
            reject()
          }
        })
      })
    },
    validationFormFillMedia() {
      return new Promise((resolve, reject) => {
        this.$refs.fillMediaRules.validate().then(success => {
          if (success) {
            resolve(true)
          } if (!success) {
            this.$swal('Please solve the errors in the page.')
            reject()
          } else {
            reject()
          }
        })
      })
    },
    validationFormStaff() {
      return new Promise((resolve, reject) => {
        this.$refs.staffRules.validate().then(success => {
          if (success) {
            resolve(true)
          } if (!success) {
            this.$swal('Please solve the errors in the page.')
            reject()
          } else {
            reject()
          }
        })
      })
    },
    resetStore() {
      if (this.$route.query.type === 'retain') {
        if (store.getters['heroAiCampaign/getCampaign'].campaign_name) {
          store.commit('heroAiCampaign/setCampaign', { campaign_name: store.getters['heroAiCampaign/getCampaign'].campaign_name })
        } else {
          this.$router.push({ name: 'campaigns-list' })
        }
      } else {
        store.commit('heroAiCampaign/setCampaign', {})
      }
      store.commit('heroAiCampaign/setCreativeBriefingInfo', {})
      store.commit('heroAiCampaign/setWebSolutionBriefing', {})
      store.commit('heroAiCampaign/setMediaBriefing', {})
      store.commit('heroAiCampaign/setChatbotSectionInfo', {})
      store.commit('heroAiCampaign/setDocumentSection', {})
      store.commit('heroAiCampaign/setSEORequirementsSection', {})
      store.commit('heroAiCampaign/setStaffAssignment', {})
      store.commit('heroAiCampaign/setCampaignProducts', {})
      store.commit('heroAiCampaign/setFillMedia', {})
      // store.commit('heroAiCampaign/setOpportunityId', null)
    },

    onCalculateTotalPrice({ nonMediaTotalPrice }) {
      this.campaign_products.transfer_amount = this.campaign_products.client_pay_for_media ? nonMediaTotalPrice : this.campaign_products.total_price
    },
  },
  formatDatePickerDate(dateValue) {
    if (dateValue) {
      return moment(dateValue).format(DATE_PICKER_FORMAT)
    }
    return ''
  },
}
</script>
<style>
  .vue-form-wizard .wizard-tab-content {
    padding: 30px 0px 10px;
  }
  .vue-form-wizard .wizard-card-footer {
    padding: 0px 0px;
  }
</style>
